import React from "React";
import Layout from "../components/Global/Layout";
import { Helmet } from "react-helmet"

import "../assets/images/develoscope-decal.png"

import "../assets/styles/contactpage.scss"

export default () => {

    const current = "contact"

    return(
        <>
        <Layout current={current}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact - Develescope</title>
          <meta name="description" content="Looking for a team to co-operate on your new project? Hit us up! We would love to help" />
        </Helmet>
        </Layout>
        </>
    )
}